<template>
  <div v-if="semaines.length > 0" class="planning">
    <planning-header
      :semaines="semaines"
      :jours="jours"
      :membres="membres"
      :creneaux="creneaux"
      :displayJoursDeRepos="displayJoursDeRepos"
      :affichage-impression="affichageImpression"
    />
    <planning-body
      :jour_nuit="jour_nuit"
      :membres="membres"
      :semaines="semaines"
      :creneaux="creneaux"
      :jours-de-repos="joursDeRepos"
      :slot-height="slotHeight"
      :affichage-impression="affichageImpression"
    ></planning-body>
  </div>
  <div class="default" v-else-if="service">
    <p class="body-1">Il y a aucun <b>modèle de planning</b> mis en application à ce jour.</p>
    <p class="body-1">
      Afin de générer un planning, veuillez définir un modèle et déclarer sa mise en application à une date anterieure à
      la date d'aujourd'hui
    </p>
    <v-btn
      color="primary"
      width="128"
      height="128"
      icon
      class="mr-2"
      :to="{
        name: 'modelePlanning',
        params: { serviceId: service.serviceId },
      }"
    >
      <v-icon size="100">fa-calendar-alt</v-icon>
    </v-btn>
  </div>
</template>

<script>
import PlanningBody from './body/planning-body.vue'
import { mapGetters } from 'vuex'
import PlanningHeader from './header/planning-header.vue'

export default {
  name: 'planning',
  components: {
    PlanningHeader,
    PlanningBody,
  },
  props: {
    affichageImpression: {
      type: Boolean,
      required: true,
    },
    creneaux: {
      type: Array,
      required: true,
    },
    joursDeRepos: {
      type: Array,
      required: true,
    },
    semaines: {
      type: Array,
      required: true,
    },
    membres: {
      type: Array,
      required: true,
    },
    jour_nuit: {
      type: String,
      required: true,
    },
    service: {
      type: Object,
      required: false,
      default: undefined,
    },
    displayJoursDeRepos: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      jours: 'calendrier/getJours',
    }),
    slotHeight() {
      return parseInt(
        this.affichageImpression === true ? process.env.VUE_APP_PRINT_SLOT_HEIGHT : process.env.VUE_APP_SLOT_HEIGHT
      )
    },
  },
  mounted() {
    this.$emit('MOUNTED')
  },
}
</script>

<style scoped>
.planning {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  overflow: hidden;
}

.default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  overflow: hidden;
}

.planning-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 60%;
  overflow: hidden;
  position: relative;
}

.planning-body-scrollable {
  display: flex;
  flex-direction: column;
}

.planning-panel {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

.planning-body-semaine {
  display: flex;
  flex-direction: row;
  flex: 1 1 60%;
  overflow-x: visible;
  position: relative;
}
</style>
