import axios from 'axios'

export default {
  namespaced: true,
  state: {
    postesAIM: [],
  },
  mutations: {
    SET_POSTES(state, _postes) {
      state.postesAIM = _postes
    },
  },
  getters: {
    getPostes(state) {
      return state.postesAIM
    },
    getIconePourPosteAimId: () => (_posteAimId) => {
      switch (_posteAimId) {
        case 'ALIMENTATION':
          return 'mdi-silverware-fork-knife'
        case 'ARGENT_DE_POCHE':
          return 'mdi-cash-multiple'
        case 'HABILLEMENT':
          return 'mdi-tshirt-crew'
        case 'COIFFURE':
          return 'mdi-hair-dryer'
        case 'HYGIENE':
          return 'mdi-toothbrush-paste'
        case 'LAVERIE':
          return 'mdi-washing-machine'
        case 'TELECOM':
          return 'mdi-cellphone-basic'
        case 'TRANSPORT':
          return 'mdi-bicycle'
        case 'CAUTION':
          return 'mdi-home'
        case 'EPARGNE':
          return 'mdi-piggy-bank'
        default:
          return 'mdi-circle'
      }
    },
  },
  actions: {
    fetchPostes({ commit }) {
      return axios.get(`${process.env.VUE_APP_URL_BACK_SERENITY}/posteAIM`).then((response) => {
        commit('SET_POSTES', response.data)
      })
    },
  },
}
